<template>
  <div>
    <headerNav></headerNav>
    <div class="header">
      <div class="header-main flex-i">
        <img class="header-logo" src="../../assets/images/login/logo.png" alt="">
        <span class="header-title">榆阳产业服务平台营销商城</span>
        <div class="">
          <!-- <input class="header-search" v-model="searchContent" type="text">
          <button class="sch_button" @click="goList">搜索</button> -->
          <!-- <div class="header-search-btn">搜索</div> -->
        </div>
      </div>
      <!-- <img src="" alt="">
      <span>榆阳产业服务平台商城</span> -->
    </div>
      <div class="main">
        
        <div class="main-title">
            电商报告（付费2元阅读全文）
        </div>
        <ul class="list">
            <li><span @click="handClick">2021年十大隔离妆前品牌价值分析报告</span></li>
            <li><span @click="handClick">网上商城项目可行性研究报告（2021版）</span></li>
            <li><span @click="handClick">商业贸易行业报告：复盘海外，结合国内特殊背景，探母婴连锁龙头发展之路</span></li>
            <li><span @click="handClick">2021-2026年中国网上商城市场前景分析及预测.</span></li>
            <li><span @click="handClick">Pantone 2021春夏流行色报告</span></li>
            <li><span @click="handClick">家用电器行业4月小家电产业在线数据报告</span></li>
            <li><span @click="handClick">2021-2026年中国网上商城市场调查及预测报告</span></li>
            <li><span @click="handClick">中国互联网行业+指数报告（2021）：数字中国脉动地图</span></li>
            <li><span @click="handClick">食品饮料行业新兴品牌数字化营销洞察报告</span></li>
        </ul>
            
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import headerNav from './../../components/headerNav'

export default {
  name: 'common',
  data(){
      return{

      }
  },
  components: {
      headerNav
  },
  mounted(){

  },
  methods:{
     handClick() {
        this.$alert('正在开发中...', {
          confirmButtonText: '确定',
          callback: action => {
            // this.$message({
            //   type: 'info',
            //   message: `action: 正在开发中...`
            // });
          }
        });
      }
   
  }
}
</script>
<style scoped>
  .header {
    width: 100%;
    height: 126px;
    background: #fff;
  }
  .header-main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .header-logo {
    width: 60px;
    height: 60px;
  }
  .header-title {
    font-size: 28px;
    color: 333;
    font-weight: 600;
    margin: 0 37px 0 10px;
  }
  .header-search-box {
    width: 718px;
    height: 46px;
    display: flex;
    align-items: center;
    border: 1px solid #84C754;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 21px;
  }
  .header-search {
    width: 570px;
    border: 0;
    
  }
  .header-search-btn {
    width: 106px;
    height: 44px;
    line-height: 44p[x];
    border: 0 8px 8px 0;
  }
  .sch_button {
    width: 106px;
    height: 46px;
    border: 0;
    margin-right: -1px;
    background: #84C754;
    font-weight: 800;
    color: #FFFFFF;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
  }
    .main {
        width: 800px;
        margin: 50px auto;
        text-align: left;
    }
    .main-title {
        font-size: 44px;
        color: #b0b0b7;

    }

    .list {
        margin-top: 40px;
        margin-left: 15px;
    }
    .list li{
        margin-bottom: 30px;
        font-size: 18px;
    }
    .list li span {
       cursor: pointer;
       text-decoration:underline;
    }
     
     .list li span:hover {
       color: #EE6D2D;
    }
</style>