<template>
  <div class="header-nav">
      <div class="header-title"></div>
      <!-- 蜂窝在线养殖后台 -->
      <ul class="header-box">
        <li v-if="!userData.cellPhone">
            <span>HI ~ 【</span>
            <span class="move-hand" @click="goLogin">请登录</span>/
            <span class="move-hand">注册</span>
            <span>】</span>
        </li>
        <li v-if="userData.cellPhone">
            <span>欢迎您, </span>
            <span>{{userData.cellPhone}}</span>
            <span class="out move-hand" @click="out"> {退出}</span>
        </li>
        <li class="move-hand" @click="follow">收藏夹</li>
        <li class="move-hand" @click="goMyOrder">我的订单</li>
        <li class="move-hand" @click="goShopList ">商家店铺</li>
        <li class="move-hand" @click="becomeBusiness" v-if="isSupplier">成为商家</li>
        <li class="move-hand" @click="goShop" v-else>我的店铺</li>
        <!-- <li class="move-hand" @click="goReport">电商报告</li> -->
        <li class="scan-code" @mouseenter="moveIn()" @mouseleave="moveOut()">
            <span class="move-hand">手机版</span> 
            <div class="code-box" v-if="isCode">
                <img class="code-img" src="../assets/images/index/code.jpg" alt="">
                <span>微信扫码</span>
            </div>
        </li>
      </ul>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'common',
  data(){
      return{
        isSupplier: true,
        isCode: false,
        type: 1,
        userData: {
            cellPhone: ''
        },
        loginType: 0, // 0未登录 1已登录 
      }
  },
  mounted(){
    this.qa.queryShop({}).then(res => {
        if(res.respCode == "0000") {
            if(res.data.approvalStatus == 1) {
                this.isSupplier = false;
            } else {
                this.isSupplier = true;
                if(res.data.approvalStatus == 2) {
                    this.type = 4;
                }
                if(res.data.approvalStatus == 0) {
                    this.type = 2;
                }
            }
        }
    })
    if(sessionStorage.getItem("userData")){
        if(!(JSON.stringify(sessionStorage.getItem("userData")) == "{}")){ 
            this.userData = JSON.parse(sessionStorage.getItem("userData"))
        }
    }
  },
  methods:{
      goShop() {
         window.location.href = 'http://8.140.113.191:9001/#/'
      },
      moveIn() {
          this.isCode = true;
      },
      moveOut() {
          this.isCode = false;
      },
      goMyOrder(){
          this.$router.push({
              name: 'myOrder',
              query: {
                  id: 2
              }
          })
      },
        goShopList () {
            this.$router.push({
              name: 'shopList',
          })
    },
      goReport() {
          this.$router.push({
              name: 'report',
          })
      },
      follow() {
          this.$router.push({
              name: 'follow',
              query: {
                  id: 4
              }
          })
      },
      goLogin() {
          this.$router.push({
              path: 'login'
          })
      },
      out() {
        this.qa.loginOut({}).then(res => {
            sessionStorage.removeItem("userData")
            this.$router.push({
                path: 'login'
            })
        })
        
      },
      becomeBusiness() {
        if(sessionStorage.getItem("userData")){
            
            if((JSON.stringify(sessionStorage.getItem("userData")) == "{}")){ 
               this.$router.push({
                    path: 'login',
                })
            }else {
                this.$router.push({
                    path: 'applicationShop',
                    query: {
                        type: this.type
                    }
                })
            }
        }else {
            this.$router.push({
                path: 'login',
            })
        }
        
      }
  }
}
</script>
<style scoped>
    .header-nav {
        width: 1200px;
        height: 40px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between
    }
    .header-box {
        width: 563px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #366713;
        font-size: 14px;
        margin-right: 57px;
    }
    .header-title {
        height: 40px;
        line-height: 40px;
        color: #366713;
        font-size: 14px;
    }
    .scan-code {
        position: relative;
    }
    .code-box{
        width: 134px;
        height: 161px;
        text-align: center;
        background: url(../assets/images/index/scan_code.png);
        position: absolute;
        top: 20px;
        left: -45px;
        font-size: 14px;
        color: 000;
    }
    .code-img {
        display: block;
        width: 106px;
        height: 106px;
        margin: 26px auto 2px;
    }
    .out {
        color: #84C754; margin-left: 10px;
    }
</style>